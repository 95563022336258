.experience{
    margin-top: 30vh;
}

.title{
    font-weight: 500;
    font-size: 22px;

}

.company{
    font-weight: 500;
    font-size: 22px;
    color: rgb(59, 96, 165);
}

.company-link{
    cursor: pointer;
    text-decoration: none;
    color: rgb(59, 96, 165);
}

.company-link:hover{
    text-decoration: none;
    
}

.company-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #18272F;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

.company-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.date {
    font-style: 12px;
    font-weight: 300;
}

.box-content{
    padding-top: 0 !important;
    margin-left: 25px;
}

.duties-list{
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    display: list-item;
}

.duties{
 margin-bottom: 10px;
 position: relative;
 padding-left: 30px;
 width: 600px;
}

.duties::before{
    content: "▹ ";
    position: absolute;
    left: 0px;
    color: rgb(59, 96, 165);
    font-size: 1em;
}
