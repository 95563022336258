.footer{
    height: 100px;
    text-align: center;
}

.footer-link{
    text-decoration: none;
    color: black;
}

.footer-link:hover{
    text-decoration: none;
    color: var(--standard-color);
}
