@tailwind base;
@tailwind components;
@tailwind utilities;

/* naming the colors I will be using throughout*/
:root{
  --standard-color: rgb(134, 172, 243);
  --standard-color-dark: rgb(59, 96, 165);
}

.full-page-structure {
  display: flex;
  font-family: "Roboto", sans-serif;
}

.body{
  
}

.right-side-div {
  flex: 1;
}

.center-div {
  flex: 5;
}

.left-side-div {
  flex: 1;
}

/* CSS */
.button-56 {
  align-items: center;
  background-color: var(--standard-color);
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-56:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: var(--standard-color);
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

.a-tag-no-features{
  color: black;
  text-decoration: none;
}

.a-tag-no-features:hover{
  text-decoration: none;
  color: var(--standard-color);
}

.box-shadow{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.color-ease{
  transition: var(--standard-color) 1s linear;
}

.color-ease-dark:hover{
  transition: var(--standard-color-dark) 1s linear;
}
