.side-bar-socials {
    margin-left: 25px;
    position: fixed;
    width: 60px;
    bottom: 0;
    z-index: 10;
    text-align: center;
}

.vertical {
    border-left: 4px solid black;
    height: 100px;
    display:inline-block;
}

.social-icon {
    font-size: 32px;
    margin-bottom: 5px;
    display:inline-block;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.social-icon:hover{
    transform: translateY(-3px);
    color: var(--standard-color);
}
